import { Store } from "redux";
import { setLocale, syncTranslationWithStore } from "react-redux-i18n";

export const getMyGameLanguage = () => localStorage.getItem("mg-language") || "en";

export const dispatchTranslations = (store: Store<any>, userLocale: string) => {
  syncTranslationWithStore(store);
  const setLocaleDispatchCallback = setLocale(userLocale);
  setLocaleDispatchCallback(store.dispatch);
};
