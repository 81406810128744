import { getMyGameLanguage } from "core/utils/i18n";

type Comparator<T> = (x: T, y: T) => number;

type PrimitiveType = number | string | Date;

export const compare = <T extends PrimitiveType>(x: T, y: T): -1 | 0 | 1 => {
  if (x < y) {
    return -1;
  }
  if (x > y) {
    return 1;
  }
  return 0;
};

/**
 * @example
 * persons.sort(comparing(it => it.age))
 */
export const comparing =
  <T>(firstMapper: (it: T) => PrimitiveType, secondMapper?: (it: T) => PrimitiveType): Comparator<T> =>
  (x, y) => {
    const result = compare(firstMapper(x), firstMapper(y));
    if (result === 0 && secondMapper !== undefined) {
      return compare(secondMapper(x), secondMapper(y));
    }
    return result;
  };

export type Order = "ASC" | "DESC";

/**
 * Builds a comparison function suitable for `sort` from a getter function
 *
 * The getter function should extract the value to process in the sorting algorithm
 */
export const cmp = <T>(f: (x: T) => string | number, order: Order = "ASC") =>
  order === "ASC"
    ? (x: T, y: T) => (f(x) > f(y) ? 1 : f(x) < f(y) ? -1 : 0)
    : (x: T, y: T) => (f(x) > f(y) ? -1 : f(x) < f(y) ? 1 : 0);

/**
 * @see String#localeCompare
 */
export const comparingLabel =
  <T>(mapper: (it: T) => string, locale: string = getMyGameLanguage()): Comparator<T> =>
  (x, y) =>
    mapper(x).localeCompare(mapper(y), locale, { sensitivity: "base" });

/**
 * Returns the indices that would sort an array
 */
export const argsort = (arr: number[]): number[] =>
  arr
    .map((x, index) => [x, index])
    .sort(cmp(([x]) => x))
    .map(([, index]) => index);
