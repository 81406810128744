import { CSSProperties, useEffect, useState } from "react";
import backgroundImage from "/src/core/assets/images/mojitoNextToBeachVolley.jpeg";
import { getMyGameLanguage } from "core/utils/i18n";
import { I18n } from "react-redux-i18n";

interface IProps {
  title?: string;
  content?: string;
}

const defaultUnavailableMsg = {
  "fr-FR": {
    MyGame: "MyGame",
    loading: "Chargement...",
    unavailable: {
      title: "Mygame est temporairement indisponible.",
      content: `<b>Bonjour à tous</b>,
                
                Nous devons effectuer une mise à jour de notre base de données suite aux soucis de connexion.
                Mygame sera fermé ce vendredi de <b>13h30 à 15h (UTC + 2)</b>. Nous sommes conscients de l'impact et faisons notre possible pour rouvrir rapidement.
                Bonne journée à tous,
                
                <b>Boris et toute l'équipe Mygame</b>`
    }
  },
  "en-US": {
    MyGame: "MyGame",
    loading: "Loading...",
    unavailable: {
      title: "Mygame is temporary unavailable.",
      content: `<b>Hello everyone</b>,
                We need to perform a database update due to connection issues.
                Mygame will be closed this Thursday from <b>13:30 to 15:00 (UTC +2)</b>. We are aware of the impact and are doing our best to reopen quickly.
                Have a great day,
                
                <b>Boris and the Mygame team</b>`
    }
  },
  es: {
    MyGame: "Mygame",
    loading: "Cargando...",
    unavailable: {
      title: "Mygame no está disponible temporalmente.",
      content: `<b>Hola a todos</b>,
                
                Necesitamos realizar una actualización de nuestra base de datos debido a problemas de conexión.
                Mygame estará cerrado este jueves de <b>13:30 a 15:00 (UTC +2)</b>. Somos conscientes del impacto y estamos haciendo todo lo posible para reabrir rápidamente.
                Que tengan un buen día,
                
                <b>Boris y todo el equipo de Mygame</b>`
    }
  }
};

const LiveUTCPlus2Time = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const getUTCPlus2Time = date => {
    const utcTime = date.getTime() + date.getTimezoneOffset() * 60000;
    return new Date(utcTime + 2 * 3600000);
  };

  const formatTime = date => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const utcPlus2Time = getUTCPlus2Time(time);

  return (
    <div>
      <p>{formatTime(utcPlus2Time)} (UTC + 2)</p>
    </div>
  );
};

const BlockedPage: React.FunctionComponent<IProps> = props => {
  const { title, content } = props;
  const [defaultMsg, setDefaultMsg] = useState({
    title: defaultUnavailableMsg["en-US"].unavailable.title,
    content: defaultUnavailableMsg["en-US"].unavailable.content
  });
  const locale = getMyGameLanguage();

  useEffect(() => {
    if (defaultUnavailableMsg[locale]) {
      setDefaultMsg({
        title: defaultUnavailableMsg[locale].unavailable.title,
        content: defaultUnavailableMsg[locale].unavailable.content
      });
    }
  }, [locale]);

  const formattedContent = defaultMsg.content.replace(/\n/g, "<br>");

  const containerStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover", // Adjust to fit the image within the container
    backgroundPosition: "center" // Position the image at the center
  };

  const boxStyle: CSSProperties = {
    padding: "1vh",
    backgroundColor: "azure",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    maxWidth: "100vh",
    margin: "0 auto"
  };

  const headerStyle: CSSProperties = {
    marginBottom: "20px",
    textAlign: "center",
    fontSize: "24px", // Add font size
    fontWeight: "bold", // Add font weight
    color: "#333" // Add color
  };

  const contentStyle: CSSProperties = {
    marginTop: "20px",
    backgroundColor: "#eeecec",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    lineHeight: "1.5"
  };

  // const boolean that check if the date is in between 13h30 and 15h (UTC + 2) for june 14 2024
  const [isInTimeRange, setIsInTimeRange] = useState(false);

  useEffect(() => {
    // Get the current date and time in UTC
    const currentDate = new Date();

    // Define the maintenance window start and end times in UTC
    const startDate = new Date(Date.UTC(2024, 5, 14, 11, 30)); // June 14, 2024, 13:30 UTC+2
    const endDate = new Date(Date.UTC(2024, 5, 14, 13, 0)); // June 14, 2024, 15:00 UTC+2

    // Check if the current date and time is within the maintenance window
    const isInRange = currentDate >= startDate && currentDate <= endDate;
    setIsInTimeRange(isInRange);
  }, []);

  return isInTimeRange ? (
    <div style={containerStyle}>
      <div style={boxStyle}>
        <div style={headerStyle}>
          <h3>{defaultMsg.title}</h3>
          <LiveUTCPlus2Time />
        </div>
        <div style={contentStyle} dangerouslySetInnerHTML={{ __html: formattedContent }}></div>
      </div>
    </div>
  ) : (
    <div className="information-container">
      <div>
        <h3>{title || I18n.t("blocked-page.title")}</h3>
      </div>
      <div>{content || I18n.t("blocked-page.content")}</div>
    </div>
  );
};

export default BlockedPage;
